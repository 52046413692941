import React from "react";
import NavSlider from "../inc/NavSlider";
import HeroSection from "../inc/HeroSection";
import PopularCourses from "../inc/PopularCourses";
import TwoColumSection from "../inc/TwoColumn";
import Courses from "../inc/Courses";
import Projects from "../inc/Projects";
import Business from "../inc/Business";
import VideoSection from "../inc/VideoSection";
import Pricing from "../inc/Pricing";
import Testimonial from "../inc/Testimonial";
import ArticlesSection from "../inc/ArticalSection";
import Toggle from "../inc/Toggle";
function Home() {
  return (
    <>
      <NavSlider/>
      <HeroSection/>
      <PopularCourses/>
      <TwoColumSection/>
      <Courses/>
      <Projects/>
      <Business/>
      <VideoSection/>
      <Pricing/>
      <Testimonial/>
      <ArticlesSection/>
    </>
  );
}


export default Home;