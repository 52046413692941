import React from "react";
import ProjectOne from "../assets/images/project-card-1.png";
import ProjectTwo from "../assets/images/project-card-2.png";
import ProjectThree from "../assets/images/project-card-3.png";
import ProjectFour from "../assets/images/project-card-4.png";

function Projects() {

    return (
        <section className="projects-block-2312">
        <div className="container">
           <h3 className="text-center projects mb-5">Our Recent <span>Projects</span></h3>
           <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                 <div className="row projects-card align-items-center mb-3 px-2 py-4">
                     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects-card-img">
                        <img src={ProjectOne} className="img-fluid" alt="Project Image One"/>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects-card-detail">
                       <a href="#"><h3>Management Data Website</h3></a> 
                        <p>
                        Do quis laborum consectetur anim proident occaecat Lorem laboris in. Laborum esse nulla dolore nostrud.    
                        </p> 
                     </div>
                 </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                 <div className="row projects-card align-items-center mb-3 px-2 py-4">
                     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects-card-img">
                        <img src={ProjectTwo} className="img-fluid" alt="Project Image One"/>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects-card-detail">
                     <a href="#"><h3>Portfolio Website</h3></a>
                        <p>
                        Do quis laborum consectetur anim proident occaecat Lorem laboris in. 
                        </p> 
                     </div>
                 </div>
            </div>
           </div>
           <div className="row">
           <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                 <div className="row projects-card align-items-center mb-3 px-2 py-4">
                     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects-card-img">
                        <img src={ProjectThree} className="img-fluid" alt="Project Image One"/>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects-card-detail">
                     <a href="#"><h3>Finance Dark Theme Design</h3></a>
                        <p>
                        Do quis laborum consectetur anim proident occaecat Lorem laboris in. Laborum esse nulla.    
                        </p> 
                     </div>
                 </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                 <div className="row projects-card align-items-center mb-3 px-2 py-4 active-card-project">
                     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects-card-img">
                        <img src={ProjectFour} className="img-fluid" alt="Project Image One"/>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects-card-detail">
                     <a href="#"><h3>Crypto Wallet</h3></a>
                        <p>
                        Do quis laborum consectetur anim proident occaecat Lorem laboris in. Laborum esse nulla dolore nostrud.
                        </p> 
                     </div>
                 </div>
            </div>
           </div>
           <div className="col-md-4 m-auto text-center d-block">
            <a href="#">
               <button className="btn btn-button mt-5 fs-6 px-5 py-2">View All Projects</button>
               </a>
               </div>
        </div>
      </section>

    );
}

export default Projects;