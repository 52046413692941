import React, {useState} from "react";
import { Parallax } from "react-parallax";

import imageAstronautImage from '../assets/images/2.png';
import Learning from '../assets/images/learning.svg';
import Projects from '../assets/images/projects.svg';
import Services from '../assets/images/services.svg';
import Pricing from '../assets/images/pricing.svg';
import sapceShip from '../assets/images/3.png';
import jubiter from '../assets/images/jubiter.png';
import Rocket from '../assets/images/4.png';
import LightModeJubitar from '../assets/images/light-mode-jubitar.png';

import simpleParallax from 'simple-parallax-js'; 
var image = document.getElementsByClassName('thumbnail-image-animation');
new simpleParallax(image);

const  HeroSection = () =>  {

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <figure className="conent-block-343 text-center">
                            <h2>Unloack Your Coding<span> Potential</span>: Learn to Code with<span> Coder’s</span> One Stop</h2>
                            <p>
                                Get coding assistance and improve your skills with our comprehensive resources, tutorials, and community support. we have something for everyone to take their coding to the next level.
                            </p>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-md-12">
                            <form>
                            <div className="d-flex gap-2 user-search-block-language">
                                <input className="form-control " type="text" placeholder="Learn Python, Java, C++" />
                                <button className="btn btn-search">Search</button>
                            </div>
                            </form>
                            </div>
                        </figure>
                        <img src={imageAstronautImage} className="image-astronaut" alt="astronaut" />
                        <div className="row user-select-category mt-5">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                <a href="#">
                                <div className="user-select-category-box  d-flex align-items-center gap-4">
                                     <img src={Learning} alt="Sun Light"/>
                                     <h4>Learning</h4>
                                </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                <a href="#">
                                <div className="user-select-category-box active-box d-flex align-items-center gap-4">
                                     <img src={Projects} alt="Sun Light"/>
                                     <h4>Projects</h4>
                                </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                <a href="#">
                               <div className="user-select-category-box d-flex align-items-center gap-4">
                                     <img src={Services} alt="Sun Light"/>
                                     <h4>Services</h4>
                                </div>
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                <a href="#">
                               <div className="user-select-category-box d-flex align-items-center gap-4">
                                     <img src={Pricing} alt="Sun Light"/>
                                     <h4>Pricing</h4>
                                </div>
                                </a>
                            </div>
                        </div>
                        <img src={sapceShip} alt="Sapce Ship" className="sapce-ship"/>
                    </div>
                </div>
            </div>
            <img src={Rocket} className="rocket" alt="Rocket"/>
            <div className="jupiter-bg">
                <img src={jubiter} alt="Jubiter" id="jubitarimage" className="thumbnail-image-animation"/>
            </div>
        </section>

    );
}

export default HeroSection;