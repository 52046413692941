import React from "react";
import ArticleImage from "../assets/images/articles-image-2.png";
import ArticleCardImageOne from "../assets/images/articles-image-2.png";
import ArticleCardImageTwo from "../assets/images/articles-image-3.png";
import ArticleCardImageThree from "../assets/images/articles-image-4.png";
import bookOpen from "../assets/images/book-open.png";
import microphone from "../assets/images/microphone-stand.svg";
import  brainCircuit from "../assets/images/brain-circuit.svg";
 const ArticlesSection = () => {
  
    return (
       <section className="articles-block-019212">
         <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex justify-content-between">
                        <h3 className="Sf-Pro-Display-Bold article">Our <span>Articles</span></h3>
                        <a href="#">
                           <button className="btn btn-button px-4">Read More Articles</button>
                        </a>
                    </div>
                    <div className="article-card px-3 mt-4 py-3">
                      <div className="row  article-row">
                        <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6">
                           <div className="article-content">
                             <p className="date text-uppercase Sf-Pro-Display-Bold">March 11, 2020</p>
                             <a href="#">
                             <h3 className="Sf-Pro-Display-Regular">In design active temper be uneasy. Thirty for remove plenty regard.</h3>
                             </a>
                              <p className="article-user-name Sf-Pro-Display-Regular mt-5">By Joel Peterso</p>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-6  col-md-6 col-sm-6">
                             <div className="article-img float-end">
                              <img src={ArticleImage} alt="Article image"/>
                             </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <a href="#">
                     <div className="card">
                        <div className="card-body p-0">
                            <div className="card-text px-4 pt-3  pb-4">
                                <p className="date text-uppercase Sf-Pro-Display-Bold">March 04, 2020</p>
                               <h3 className="mb-4 Sf-Pro-Display-Regular">Partiality on or continuing particular principles as.</h3>
                            <p className="article-user-name Sf-Pro-Display-Regular">By Jennifer K.</p>
                            </div>
                            <div className="card-img px-3">
                                  <img src={ArticleCardImageOne} alt="article card image one"/>
                              </div>                           
                        </div>
                     </div>
                    </a>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <a href="#">
                     <div className="card">
                        <div className="card-body p-0">
                            <div className="card-text px-4 pt-3  pb-4">
                                <p className="date text-uppercase Sf-Pro-Display-Bold">March 04, 2020</p>
                               <h3 className="mb-4 Sf-Pro-Display-Regular">Wise busy past both park when an Nay likely her..</h3>
                            <p className="article-user-name Sf-Pro-Display-Regular">By Jennifer K.</p>
                            </div>
                            <div className="card-img px-3">
                                  <img src={ArticleCardImageTwo} alt="article card image one"/>
                              </div>                           
                        </div>
                     </div>
                    </a>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <a href="#">
                     <div className="card">
                        <div className="card-body p-0">
                            <div className="card-text px-4 pt-3  pb-4">
                                <p className="date text-uppercase Sf-Pro-Display-Bold">March 04, 2020</p>
                               <h3 className="mb-4 Sf-Pro-Display-Regular">Partiality on or continuing particular principles as.</h3>
                            <p className="article-user-name Sf-Pro-Display-Regular">By Jennifer K.</p>
                            </div>
                            <div className="card-img px-3">
                                  <img src={ArticleCardImageThree} alt="article card image one"/>
                              </div>                           
                        </div>
                     </div>
                    </a>
                </div>
            </div>

            <div className="card-box-019282 px-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                       <figure className="d-flex gap-3">
                        <div className="bookOpen">
                           <img  src={bookOpen}  alt="icon One"/>
                        </div>
                        <div className="card-content">
                        <a href="#">
                         <h3 className="read Sf-Pro-Display-Regular mb-1">Read</h3>
                        </a>
                            <p className="Sf-Pro-Display-Regular">SED Sheet - A Complete Guide</p>
                        </div>
                       </figure>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <figure className="d-flex gap-3">
                        <div className="bookOpen">
                           <img  src={brainCircuit}  alt="icon Two"/>
                        </div>
                        <div className="card-content">
                        <a href="#">
                         <h3 className="read Sf-Pro-Display-Regular mb-1">Practise</h3>
                        </a>
                            <p className="Sf-Pro-Display-Regular">SED Sheet - Learn for Learn</p>
                        </div>
                       </figure>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <figure className="d-flex gap-3">
                        <div className="bookOpen">
                           <img  src={microphone}  alt="icon Three"/>
                        </div>
                        <div className="card-content">
                        <a href="#">
                         <h3 className="read Sf-Pro-Display-Regular mb-1">Learn</h3>
                        </a>
                            <p className="Sf-Pro-Display-Regular">Interview Preparation</p>
                        </div>
                       </figure>
                    </div>
                </div>
            </div>
         </div>
       </section>
    );

}
export default ArticlesSection