import React from "react";
import ListCheck from "../assets/images/list-check.svg";
import Code from "../assets/images/code.svg";
import ChartLine from "../assets/images/chart-line-up.svg";
import Wallet from "../assets/images/wallet.svg";

const Business = () => {
  return (
   <section className="business-block-0129">
       <div className="container">
                <figure className="text-center">
                    <h3>How can we help your <span>Business ?</span></h3>
                    <p>
                    We build readymade websites, mobile applications, and elaborate online business services.
                    </p>
                </figure>
            <div className="row mt-5">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                 <div className="card">
                     <img src={ListCheck} className="bussiness-card-img" alt="List Check" />  
                    <div className="card-body mt-5">
                        <a href="#"><h5 className="card-title">Business Idea Planning</h5></a>
                        <p className="card-text"> We present you a proposal and discuss niffty-gritty like</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card">
                     <img src={ChartLine} className="bussiness-card-img" alt="List Check" />  
                    <div className="card-body mt-5">
                        <a href="#"><h5 className="card-title">Financial Management System</h5></a>
                        <p className="card-text"> We present you a proposal and discuss niffty-gritty like</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card">
                     <img src={Code} className="bussiness-card-img" alt="List Check" />  
                    <div className="card-body mt-5">
                        <a href="#"><h5 className="card-title">Development Website and App </h5></a>
                        <p className="card-text"> We present you a proposal and discuss niffty-gritty like</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card">
                     <img src={Wallet} className="bussiness-card-img" alt="List Check" />  
                    <div className="card-body mt-5">
                        <a href="#"><h5 className="card-title">Market Analysis Project</h5></a>
                        <p className="card-text"> We present you a proposal and discuss niffty-gritty like</p>
                        </div>
                    </div>
                </div>
            </div>
       </div>
   </section>
  );
}
export default  Business;