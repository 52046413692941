import React from 'react';
import Navbar  from './components/inc/Navbar';
import Footer  from './components/inc/Footer';

import Home   from './components/pages/Home';
import About   from './components/pages/About';
import Contact   from './components/pages/Contact';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <Router>
            <Navbar/>

        <Routes>
       
              <Route exact path="/" element={<Home />} />
              <Route  path="/about" element={<About />} />
              <Route  path="/contact" element={<Contact />} />
            
          </Routes>
          <Footer/>
    </Router>
  );
}


export default App;
