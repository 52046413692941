import React, { useRef, useState } from "react";
import VideoImage from "../assets/images/video-image.png"

const VideoSection = () => {
   
const VideoRef = useRef(); 
   const [show, setShow] = useState(true);
   const handleVideo = () => {
    setShow(!show);
    if (show === true)
    {
        VideoRef.current.play();
    }
    else 
    {
        VideoRef.current.pause();
        setShow(!show);
    }
 }
    return (
    <section className="video-block-87121">
       <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-12">
               <div className="video position-relative">
               <video width="95%" height="90%"  id="video"  onClick={handleVideo}   preload="metadata"  poster={VideoImage} ref={VideoRef}>
                    <source src="https://assets.mixkit.co/videos/preview/mixkit-flying-through-the-clouds-with-the-radiant-sun-14171-large.mp4"  onClick={ ()=>setShow(true) } type="video/mp4"/>
                </video>:null
                {
                 show?<div className="play-btn" title="Play video"  onClick={handleVideo}  href="#">
                    <i className="bi bi-play-fill play-icon" onClick={   () =>setShow(!show) }  id="circle-play-b"></i></div>:null
                 }
                 </div>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-5 col-sm-12">
                <figure className="mt-5">
                    <h3 className="mb-4">Take the first step to <span>knowledge</span> with Coder's One Stop</h3>
                    <p>
                    Do quis laborum consectetur anim proident occaecat Lorem laboris in. Laborum esse nulla dolore nostrud sunt adipisicing fugiat. Ex incididunt velit dolor anim consequat ipsum.
                    </p>
                    <a href="#"><button className="btn btn-button px-4 mt-4 py-2">Found Out More</button></a>
                </figure>
            </div>
        </div>
       </div>
    </section>
    
    );
}

export  default VideoSection;