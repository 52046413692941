import React from "react";
import Basic from "../assets/images/Basic.svg";
import Enterprise from "../assets/images/Enterprise.svg";
import Startups from "../assets/images/startups.svg";

function Pricing() {

    return (
    <section className="pricing-block-0921">
            <div className="container">
                    <figure className="text-center">
                        <h3 className="Sf-Pro-Display-Bold">Simple, transparent <span>pricing</span></h3>
                        <p className="Sf-Pro-Display-Regular">Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere vel venenatis eu sit massa volutpat.</p>
                        <div className="d-flex gap-4 justify-content-center align-items-center pricing-annualy-monthly-block Sf-Pro-Display-Regular mt-4">
                               <h4 className="monthly-pricing fs-4">Monthly</h4>
                               <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                </div>
                               <h4 className="monthly-annually fs-4">Annually</h4>
                        </div>
                    </figure>
                <div className="row mt-5">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                          <div className="pricing-card p-5">
                            <div className="pricing-card-body">
                                <div className="d-flex gap-4 ms-3 pricing-card-header">
                                    <div className="img-svg-icon">
                                        <img  src={Basic} alt="Basic Pricing"/>
                                    </div>
                                    <span>
                                        <h4 className="mb-0">For Individuals</h4>
                                        <h3>Basic</h3>
                                    </span>
                                </div>
                                <div className="pricing-detail mt-4">
                                     <p>
                                     Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. 
                                     </p>
                                     <figure>
                                        <span className="mt-2 d-flex gap-2 align-items-center">
                                            <h3>$99</h3>
                                            <p className="mb-0">/monthly</p>
                                        </span>
                                        <h5 className="mt-3 mb-3">What’s included</h5>
                                        <ul className="list-item  p-0 mt-4">
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>All analytics features</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 250,000 tracked visits</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Normal support</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 3 team members</li>
                                        </ul>
                                        <div className="d-flex justify-content-center  pt-4 pb-3">
                                            <a href="#">
                                            <button className="btn btn-button fs-5 px-5 py-2">Get Started</button>
                                            </a>
                                        </div>
                                     </figure>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                          <div className="pricing-card p-5 active-card-pricing position-relative">
                            <div className="pricing-card-body">
                                <div className="d-flex gap-4 ms-3 pricing-card-header">
                                <div className="img-svg-icon">
                                    <img  src={Startups} alt="Basic Pricing"/>
                                 </div>
                                    <span>
                                        <h4 className="mb-0">For startups</h4>
                                        <h3>Pro</h3>
                                    </span>
                                </div>
                                    <button className="popular-btn Sf-Pro-Display-Regular">Popular</button>
                                <div className="pricing-detail mt-4">
                                     <p>
                                     Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. 
                                     </p>
                                     <figure>
                                        <span className="mt-2 d-flex gap-2 align-items-center">
                                            <h3>$199</h3>
                                            <p className="mb-0">/monthly</p>
                                        </span>
                                        <h5 className="mt-3 mb-3">What’s included</h5>
                                        <ul className="list-item  p-0 mt-4">
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>All analytics features</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 1,000,000 tracked visits</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Premium support</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 10 team members</li>
                                        </ul>
                                        <div className="d-flex justify-content-center  pt-4 pb-3">
                                            <a href="#">
                                            <button className="btn btn-button fs-5 px-5 py-2">Get Started</button>
                                            </a>
                                        </div>
                                     </figure>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                          <div className="pricing-card p-5">
                            <div className="pricing-card-body">
                                <div className="d-flex gap-4 ms-3 pricing-card-header">
                                <div className="img-svg-icon">
                                    <img  src={Enterprise} alt="Basic Pricing"/>
                                    </div>
                                    <span>
                                        <h4 className="mb-0">For big companies</h4>
                                        <h3>Enterprise</h3>
                                    </span>
                                </div>
                                <div className="pricing-detail mt-4">
                                     <p>
                                     Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit. 
                                     </p>
                                     <figure>
                                        <span className="mt-2 d-flex gap-2 align-items-center">
                                            <h3>$399</h3>
                                            <p className="mb-0">/monthly</p>
                                        </span>
                                        <h5 className="mt-3 mb-3">What’s included</h5>
                                        <ul className="list-item  p-0 mt-4">
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>All analytics features</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 50,000 tracked visits</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Dedicated support</li>
                                            <li className="mb-3"><span><i className="bi bi-check-circle me-3"></i></span>Up to 50 team members</li>
                                        </ul>
                                        <div className="d-flex justify-content-center  pt-4 pb-3">
                                            <a href="#">
                                            <button className="btn btn-button fs-5 px-5 py-2">Get Started</button>
                                            </a>
                                        </div>
                                     </figure>
                                </div>
                            </div>
                          </div>
                      </div>
                </div>
            </div>
    </section>

    ) 
} 
export default Pricing;