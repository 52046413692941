import React from "react";
import Footerlogo from "../assets/images/logo-black.png";
import FaceBook from "../assets/images/facebook-f.svg";
import Instagram from "../assets/images/instagram.svg";

const Footer = () => {
    return(
    <footer className="footer-block-section">
    <div className="container">
        <div className="row">
           <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="footer-logo mb-3">
                 <img src={Footerlogo} alt="footer logo"/>
            </div>
            <p className="footer-about Sf-Pro-Display-Regular">
            Aliquam orci a nullam tempor sapien gravida donec enim ipsum porta justo velna an auctor undo congue magna laoreet augue sapien
            </p> 
           </div>
           <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
            <h3 className="footer-h  Sf-Pro-Display-Regular">Company</h3>
            <ul className="list-group   mt-4">
                <li className="mb-3"><a href="#">Careers</a></li>
                <li className="mb-3"><a href="#">Form the Bolg</a></li>
                <li className="mb-3"><a href="#">Privacy Policy</a></li>
                <li className="mb-3"><a href="#">Terms of Use</a></li>
            </ul>
           </div>
           <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
           <h3 className="footer-h  Sf-Pro-Display-Regular">Learn</h3>
            <ul className="list-group mt-4">
                <li className="mb-3"><a href="#">DSA</a></li>
                <li className="mb-3"><a href="#">Data Structures</a></li>
                <li className="mb-3"><a href="#">Algorithums</a></li>
                <li className="mb-3"><a href="#">Languages</a></li>
            </ul>
           </div>
           <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
           <h3 className="footer-h  Sf-Pro-Display-Regular fs-4">Stay In Touch</h3>
        <form>
               <div className="d-flex gap-2   user-search-block-language">
                <input className="form-control w-100" type="email" placeholder="Your email address" />
                <button className="btn btn-search">Subscribe</button>
           </div>
        </form>
           </div>
        </div>
    </div>
    <div className="footer-copyright pt-4">
        <div className="container">
            <div className="d-flex justify-content-between">
                <p className="Sf-Pro-Display-Regular fs-6">Copyright © 2023. Powered by Art Curries.</p>
                <span className="d-flex gap-4">
                 <a href="#"><img src={FaceBook} alt="FaceBook"/></a>
                 <a href="#"><img src={Instagram} alt="Instagram"/></a>
                </span>
            </div>
        </div>
    </div>
    </footer>
    );
}
export default Footer; 