import React from "react";

function Contact() {
  return (
    <>
    <h1>Contact</h1>
    </>
  );
}

export default Contact;