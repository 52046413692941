import React from "react";
import ReactImage from "../assets/images/React.svg";
import  PhpImage from "../assets/images/php.svg";
import  CImage from "../assets/images/C.svg";
import  NetImage from "../assets/images/dot-net-white.svg";
import  PythonImage from "../assets/images/python-white-logo.svg";
import  JavaImage from "../assets/images/java.svg";


function PopularCourses() {

    return (
        <section className="position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <figure className="courses">
                            <h3>Most<span>Popular</span>Courses</h3>
                            <h5>Explore from 2,769 online courses in 20 categories</h5>
                            <div className="row language-block-765">
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                    <img src={ReactImage} alt="React Image"/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <img src={PhpImage} alt="Php Image"/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <img src={CImage} alt="C Image"/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <img src={NetImage} alt=".Net Image"/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <img src={PythonImage} alt="Python Image"/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <img src={JavaImage} alt="Java Image"/>
                                </div>
                            </div>
                            <a href="#">
                            <button className="btn btn-button mt-5 fs-6 px-5 py-2">View All Categories</button>
                            </a>
                        </figure>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default PopularCourses;