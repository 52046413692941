import React, {useContext, useEffect , useRef, useState} from "react";
import darklogo from '../assets/images/logo-black.png';
import lightlogo from '../assets/images/light-logo.png';
import Navbar from "./Navbar";

const Toggle = () => {

    const [theme, setTheme] = useState("dark");
    useEffect(() => {

      document.querySelector("body").setAttribute("data-theme", "dark");      
      setTheme("dark");
    
    },[]);
    const initialState = {
        theme : "light",
    }
    
    const toggleTheme = (e)  => {
      

      
      if (e.target.checked) 
      {
          document.querySelector("body").setAttribute("data-theme", "dark");      
          localStorage.setItem("selectedTheme", "dark"); 
          setTheme("dark");
      } 
      else 
      {
        document.querySelector("body").setAttribute("data-theme", "light");      
        localStorage.setItem("selectedTheme", "light");
        setTheme( "light");
      } 

  };

return (
    <div className="desktop-block1231098">
        <label className="toggle-wrap">
        <input type="checkbox" onChange={toggleTheme} defaultChecked={theme === "dark"} className="toggle-switch"  />
        <span className="toggle" >
            < i className="bi bi-sun"></i>
            <i className="bi bi-moon"></i>
        </span>
        </label>
    </div>
)
}
export default Toggle