import React,{useRef, useState} from "react";
import {Link} from 'react-router-dom';
import darklogo from '../assets/images/logo-black.png';
import lightlogo from '../assets/images/light-logo.png';
import Toggle from "./Toggle";

const  Navbar =  () => {
  return (

      <nav className="navbar navbar-expand-lg navbar-light navbar-bg pt-3 pb-3">
        <div className="container">
          <Link to="/" className="navbar-brand">
             <img src={darklogo} alt="dark logo" id="logo"/>  
        </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="bi bi-list"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-3 mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page">Home</Link>
              </li>
              <li className="nav-item">
                <Link  to="/about" className="nav-link">Learnings</Link>
              </li>
              <li className="nav-item">
                <Link  to="/" className="nav-link">Projects</Link>
             </li>
             <li className="nav-item">
                <Link  to="/" className="nav-link">Services</Link>
             </li>
             <li className="nav-item">
                <Link  to="/" className="nav-link">Pricing</Link>
             </li>
            </ul>
            <figure className="ms-auto mb-0">
                <div className=" user-block-542 ms-auto d-flex flex-wrap align-items-center gap-4">
                    {/* Toggle Button */}
                   <Toggle/>
                    {/* Register Button*/}
                    <a href="#" className="text-decoration-none">Register</a>
                    {/* Sign In Button*/}
                    <a href="#">
                    <button className="btn btn-sign" type="button">Sign In</button>
                    </a>
                </div>
            </figure>
          </div>
        </div>
            <figure className="ms-auto mb-0">
                <div className=" user-block-542  ms-auto d-flex flex-wrap align-items-center gap-4">
                    {/* Toggle Button */}
                    {/* <div className="mobile-block1231098">
                      <label className="toggle-wrap">
                        <input type="checkbox"  className="toggle-switch" />
                        <span className="toggle">
                          < i className="bi bi-sun"></i>
                          <i className="bi bi-moon"></i>
                        </span>
                      </label>
                    </div> */}
                </div>
            </figure>
      </nav>
      



   );
}

export default Navbar;