import React from "react";

function About() {
  return (
    <>
    <h1>About Us</h1>
    </>
  );
}

export default About;