import React from "react";
import cardOne from '../assets/images/card-1.png';
import cardTwo from '../assets/images/card-2.png';
import cardThree from '../assets/images/card-3.png';
import cardFour from '../assets/images/card-4.png';

const  Courses = () =>  {

    return (
        <section className="courses-block-8657">
            <h3>Courses of <span>Data Structure</span></h3>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="card mb-3">
                            <img src={cardOne} className="card-img-top" alt="..." />
                            <div className="card-body px-0">
                                <a href="#"><h5 className="card-title">Array and its types </h5></a>
                                <p className="card-text"> Do quis laborum consectetur anim proident occaecat Lorem laboris in.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="card card-active  mb-3">
                            <img src={cardTwo} className="card-img-top" alt="..." />
                            <div className="card-body px-0">
                                <a href="#"><h5 className="card-title">Linked List </h5></a>
                                <p className="card-text"> Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim..</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="card mb-3">
                            <img src={cardThree} className="card-img-top" alt="..." />
                            <div className="card-body px-0">
                                <a href="#"><h5 className="card-title">Stack </h5></a>
                                <p className="card-text"> Do quis laborum consectetur anim proident occaecat.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="card mb-3">
                            <img src={cardFour} className="card-img-top" alt="..." />
                            <div className="card-body px-0">
                                <a href="#"><h5 className="card-title">Queue and its types </h5></a>
                                <p className="card-text">Do quis laborum consectetur anim proident occaecat Lorem laboris in. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 m-auto text-center d-block">
                        <a href="#">
                            <button className="btn btn-button mt-5 fs-6 px-5 py-2">View All Courses</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Courses;