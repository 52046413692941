import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination } from "swiper";
import UserOne from "../assets/images/testimonial-image-1.png";
import UserTwo from "../assets/images/testimonial-image-2.png";
import UserThree from "../assets/images/testimonial-image-3.png";
const Testimonial = () => {
  return (

 <section className="testimonial-bloclk-0129">
         <div className="container">
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 colsm-12">
                         <h3 className="text-center Sf-Pro-Display-Bold">What people are saying <span>about us</span></h3>
                </div>
            </div>
            {/* Testimonial Slider Here */}
            <div className="Testimonial-slider mt-5">
            <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    draggable={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={
                        {
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        480: {
                          slidesPerView: 4,
                          spaceBetween: 24,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 24,
                        },
                        1440: {
                          slidesPerView: 3,
                          spaceBetween: 24,
                        },
                      }
                    }
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserOne} alt="User One"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular{">Joel Peterson</h4>
                                    <h5 className="user-bio fs-6">UX Designer</h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                    <SwiperSlide>
                    <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserTwo} alt="User Two"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular{">Jennifer Mark</h4>
                                    <h5 className="user-bio fs-6">Data Engineer</h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserThree} alt="User Three"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular">Mark Walberg </h4>
                                    <h5 className="user-bio fs-6">Student </h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserOne} alt="User One"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular{">Joel Peterson</h4>
                                    <h5 className="user-bio fs-6">UX Designer</h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                    <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserTwo} alt="User Two"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular{">Jennifer Mark</h4>
                                    <h5 className="user-bio fs-6">Data Engineer</h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="testimonial-card px-4 py-5">
                            <div className="testimonial-card-detail mb-5">
                                 <p className="mb-0 Sf-Pro-Display-Regular">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna
                                 </p>
                            </div>
                            <div className="testimonial-card-user d-flex gap-3">
                                 <div className="testimonial-user-img">
                                    <img src={UserOne} alt="User One"/>
                                 </div>
                                 <div className="testmonial-user-name">
                                   <h4 className="user-name fs-5 mb-1 Sf-Pro-Display-Regular{">Joel Peterson</h4>
                                    <h5 className="user-bio fs-6">UX Designer</h5>
                                 </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                </Swiper>
            </div>
            {/* End Testimonial  Slider */}
         </div>
   </section>
 
   );
}
export  default Testimonial;