import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation";
import { Navigation} from "swiper";


const NavSlider = () => {
  return (
<>
<Swiper
        slidesPerView={15}
        // spaceBetween={5}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        breakpoints={
          {
          320: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
          1440: {
            slidesPerView: 6,
            spaceBetween: 24,
          },
        }
      }
        className="mySwiper slider-block312"
      >
        <SwiperSlide>Data Structure</SwiperSlide>
        <SwiperSlide>Algorithums</SwiperSlide>
        <SwiperSlide>Interview Preparation</SwiperSlide>
        <SwiperSlide>Data-science</SwiperSlide>
        <SwiperSlide>Topic wise Practisce</SwiperSlide>
        <SwiperSlide>C</SwiperSlide>
        <SwiperSlide>C++</SwiperSlide>
        <SwiperSlide>Javascript</SwiperSlide>
        <SwiperSlide>Pthyon</SwiperSlide>
        <SwiperSlide>Latest blogs</SwiperSlide>
        <SwiperSlide>Competitve Programimg</SwiperSlide>
        <SwiperSlide>Machine Learning</SwiperSlide>
        <SwiperSlide>Java</SwiperSlide>
        <SwiperSlide>Ruby</SwiperSlide>
        <SwiperSlide>Wordpress</SwiperSlide>
        <SwiperSlide>Html</SwiperSlide>
        <SwiperSlide>Css</SwiperSlide>
      </Swiper>
</>


   );
}

export default NavSlider;