import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import  'bootstrap/dist/css/bootstrap.min.css';
import  'bootstrap/dist/js/bootstrap.bundle.min.js';

import './components/assets/css/style.css';
import './components/assets/css/responsive.css';

import './components/assets/js/app.js';

import './components/assets/fonts/SFPRODISPLAYBOLD.OTF';
import './components/assets/fonts/SFPRODISPLAYREGULAR.OTF';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'simple-parallax-js/dist/simpleParallax.min';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


reportWebVitals();
