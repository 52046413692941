import React from "react";
import SapceAstrounte from '../assets/images/square-323.png';
import SapceHuman from '../assets/images/383963889.png';

const TwoColumSection = () => {

    return (
        <section className="mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 align-self-end">
                        <figure className="terminologies-block-6879">
                            <h3>
                                Important Key <span>Concepts</span> &<br />
                                <span>Terminologies</span>
                            </h3>
                            <h4 className="text-uppercase">LEARN SYSTEM DESIGN</h4>
                            <p>
                                Do quis laborum consectetur anim proident occaecat Lorem laboris in. Laborum esse nulla dolore nostrud sunt adipisicing fugiat. Ex incididunt velit dolor anim consequat ipsum. Sunt reprehenderit dolore esse labore non. Et nulla do nulla enim. Deserunt esse reprehenderit irure esse ad et ad minim elit. Aute magna in fugiat enim id sunt ea. Nostrud ut et occaecat id laboris cillum reprehenderit velit ex.
                            </p>
                            <a href="#">
                                <button className="btn btn-button px-3">
                                    Read More<i className="bi bi-arrow-up-right ms-2"></i>
                                </button>
                            </a>
                        </figure>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                           <div className="sapce-astronte">
                                    <img src={SapceAstrounte} className="square-image" alt="Sapce Astrounte"/>
                                    <img src={SapceHuman} alt="Sapce Human" className="sapce-human"/>
                           </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default TwoColumSection;